<template>
  <Modal
    id="modal_devis_reason"
    :variant="variantModal"
  >
    <template #title>
      {{ type === 'modification' ? $t('devis.demande-de-modification') : $t('devis.devis-rejete') }}
    </template>
    <template #subtitle>
      <template
        v-if="type === 'modification'
          && devis.user_from_demande_modification_verbose
          && devis.user_from_demande_modification_verbose.nom"
      >
        {{ $t('general.par') }} {{ devis.user_from_demande_modification_verbose.nom }}
      </template>
      <template v-else-if="type === 'rejet' && devis.user_rejet_verbose">
        {{ $t('general.par') }} {{ devis.user_rejet_verbose.nom }}
      </template>
    </template>
    <template #content>
      <p
        v-if="message"
        class="text-regular message"
      >
        <strong>{{ $t('general.message') }}</strong>
        <em>{{ message }}</em>
      </p>
    </template>
    <template #footer>
      <ButtonClassic
        v-if="type === 'rejet'"
        variant="solid"
        :label="$t('action.continuer')"
        color="primary"
        size="medium"
        icon="right"
        @click="$modal.hide('modal_devis_reason')"
      >
        <template #right-icon>
          <UilArrowRight />
        </template>
      </ButtonClassic>
      <ButtonClassic
        variant="solid"
        :label="type === 'modification' ? $t('action.modifier-le-devis') : $t('action.supprimer-devis')"
        color="secondary"
        icon="left"
        size="medium"
        @click="submit()"
      >
        <template #left-icon>
          <UilSyncExclamation v-if="type === 'modification'" />
          <UilTrashAlt v-else />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import { ButtonClassic, Modal } from "@lde/core_lde_vue";

import { UilSyncExclamation, UilTrashAlt, UilArrowRight } from "@iconscout/vue-unicons";

/**
 * Modale affichée lorsque l'utilisateur reçoit une demande de modification ou un rejet de son devis.
 */
export default {
  name: "ModalDevisReason",
  components: {
    Modal,
    ButtonClassic,
    UilSyncExclamation,
    UilTrashAlt,
    UilArrowRight,
  },
  props: {
    /**
     * Devis courant.
     */
    devis: {
      type: Object,
      required: true,
    },
    /**
     * Change l'affichage de la modale (couleurs, contenu...).
     */
    type: {
      type: String,
      validator: (value) => ["modification", "rejet"].includes(value),
      default: "modification",
    },
  },
  emits: [
    /**
     * Déclenché à la validation de l'action de la modale.
     */
    "delete-devis",
  ],
  computed: {
    variantModal() {
      return this.type === "modification" ? "warning" : "error";
    },
    message() {
      return this.type === "modification" ? this.devis.raison_demande_modification : this.devis.se_raison_refus;
    },
  },
  methods: {
    /**
     * Ferme la modale via le bouton principal validant l'action.
     */
    submit() {
      if (this.type === "rejet") {
        this.$emit("delete-devis");
      }
      this.$modal.hide("modal_devis_reason");
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_devis_reason {
  .modal-body { min-width: 75rem; }

  .modal-footer {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}
</style>
