<template>
  <Modal
    id="modal_devis_to_liste"
    element-to-focus="input"
  >
    <template #title>
      {{ $t('devis.repassage-du-devis-en-liste') }}
    </template>
    <template #content>
      <p class="text-regular message">
        {{ $t('devis.vous-etes-sur-le-point-repasser-devis-en-liste') }}
      </p>
      <InputClassic
        v-model="textInput"
        type="text"
        :label="$t('liste.nom-de-la-liste')"
        :placeholder="devis.libelle || `${$t('liste.exemple-terminale')}`"
        @keyup.enter.native="confirm()"
      />
    </template>
    <template #footer>
      <ButtonClassic
        variant="solid"
        :label="$t('devis.repasser-devis-en-liste')"
        color="primary"
        icon="right"
        size="medium"
        @click="confirm()"
      >
        <template #right-icon>
          <IconBookToDevisLeft />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import { ButtonClassic, Modal, InputClassic } from "@lde/core_lde_vue";

import IconBookToDevisLeft from "@/components/icons/IconBookToDevisLeft.vue";

/**
 * Modale affichée pour le passage d'un devis en liste.
 */
export default {
  name: "ModalDevisToListe",
  components: {
    Modal,
    ButtonClassic,
    InputClassic,
    IconBookToDevisLeft,
  },
  props: {
    /**
     * Information du devis courant.
     */
    devis: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    /**
     * Déclenché lorsqu'on confirme la suppression.
     */
    "confirm",
  ],
  data() {
    return {
      textInput: "",
    };
  },
  methods: {
    /**
     * Lorsqu'on confime.
     */
    confirm() {
      this.$emit("confirm", this.textInput);
      this.$modal.hide("modal_devis_to_liste");
    },
  },
};
</script>

<style lang="scss"></style>
