<template>
  <Modal id="modal_devis_ask_reason">
    <template #title>
      {{ type === 'modification' ? $t('devis.demande-de-modification-de-devis') : $t('devis.rejet-d-un-devis') }}
    </template>
    <template #subtitle>
      <!-- @slot Emplacement pour le sous-titre de la modale. -->
      <slot name="subtitle" />
    </template>
    <template #content>
      <InputClassic
        v-model="text"
        type="textarea"
        :label="$t('general.votre-message')"
        :placeholder="$t('general.placeholder-raison-demande')"
        status="required"
        required
      >
        <template #label-icon>
          <UilCommentDots />
        </template>
      </InputClassic>
    </template>
    <template #footer>
      <ButtonClassic
        variant="solid"
        :label="$t('action.envoyer')"
        color="secondary"
        icon="right"
        size="medium"
        :disabled="!text || text.trim() === ''"
        @click="send()"
      >
        <template #right-icon>
          <UilNavigator />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import { ButtonClassic, InputClassic, Modal } from "@lde/core_lde_vue";

import { UilCommentDots, UilNavigator } from "@iconscout/vue-unicons";

/**
 * Modale de demande de modification d'un devis.
 */
export default {
  name: "ModalDevisAskReason",
  components: {
    Modal,
    ButtonClassic,
    InputClassic,
    UilCommentDots,
    UilNavigator,
  },
  props: {
    /**
     * Type de demande.
     */
    type: {
      type: String,
      validator: (value) => ["modification", "rejet"].includes(value),
      default: "modification",
    },
  },
  emits: [
    /**
     * Lorsqu'on envoie la demande de modification.
     */
    "send",
  ],
  data() {
    return {
      text: "",
    };
  },
  methods: {
    /**
     * Envoie la demande de modification ou le refus du devis courant.
     */
    send() {
      if (this.text) {
        this.$emit("send", this.text);
      }
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_devis_ask_reason {
  .subtitle { color: var.$gray-dark; }

  .modal-body {
    min-width: 75rem;
    p { padding: var.$space-xx-tiny var.$space-tiny var.$space-tiny; }
  }

  .message { color: var.$blue; }
}
</style>
