<template>
  <Modal
    id="modal_set_name"
    element-to-focus="input"
    @change="!$event && hide()"
  >
    <template #title>
      {{ $t("general.renommer") }}
    </template>
    <template #content>
      <p class="text-regular">
        <template v-if="variant === 'liste'">
          {{ $t('liste.point-de-renommer-cette-liste') }}
        </template>
        <template v-if="variant === 'devis'">
          {{ $t('devis.vous-etes-sur-le-point-renommer-ce-devis') }}
        </template>
      </p>
      <InputClassic
        v-model="nameInput"
        v-bind="inputAttr"
        @keyup.enter.native="submit()"
      />
    </template>
    <template #footer>
      <button
        class="button text-medium underline"
        @click="hide()"
      >
        {{ $t("action.annuler") }}
      </button>
      <ButtonClassic
        variant="solid"
        :label="$t('action.valider')"
        color="primary"
        icon="right"
        :disabled="rename && !nameInput"
        @click.once="submit()"
      >
        <template #right-icon>
          <UilCheckCircle />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import {
  ButtonClassic,
  InputClassic,
  Modal,
} from "@lde/core_lde_vue";

import { UilCheckCircle } from "@iconscout/vue-unicons";

/**
 * Modale permettant de nommer ou de renommer un élément (une liste, un devis, une commande...).
 */
export default {
  name: "ModalSetName",
  components: {
    ButtonClassic,
    Modal,
    InputClassic,
    UilCheckCircle,
  },
  props: {
    /**
     * Informations relatives à la liste.
     */
    libelle: {
      type: String,
      default: "",
    },
    /**
     * Définit le contenu de la modale.
     */
    variant: {
      type: String,
      validator: (value) => ["liste", "devis", "commande", "etiquette"].includes(value),
      required: true,
    },
    /**
     * Définit si on renomme l'objet en cours.
     */
    rename: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /**
     * Déclenché lorsqu'on confirme le renommage.
     */
    "submit",
  ],
  data() {
    return {
      nameInput: "",
    };
  },
  computed: {
    inputAttr() {
      const props = { placeholder: this.rename ? this.$t("liste.exemple-terminale") : this.libelle };

      if (this.variant === "liste") {
        props.label = this.$t("liste.nom-de-la-liste");
      } else if (this.variant === "devis") {
        props.label = this.$t("devis.nom-du-devis");
        if (!this.rename) {
          props.label += ` (${this.$t("general.optionnel")})`;
        }
      } else if (this.variant === "etiquette") {
        props.label = `${this.$t("devis.nom-de-commande")} (${this.$t("general.optionnel")})`;
      } else {
        props.label = `${this.$t("devis.nom-de-commande")} (${this.$t("general.optionnel")})`;
      }

      return props;
    },
  },
  methods: {
    /**
     * Valide l'action de la modale.
     */
    submit() {
      if (this.variant !== "liste" || this.nameInput) {
        this.$emit("submit", this.nameInput);
      }
    },
    /**
     * Réinitialise toutes les valeurs une fois que la modale a été fermée.
     */
    hide() {
      this.$modal.hide("modal_set_name");
      this.nameInput = null;
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_set_name {
  .modal-content { width: 73.6rem; }

  .modal-body {
    overflow: unset;
    > *:not(:last-child) { margin-bottom: var.$space-x-tiny; }
  }

  .modal-footer {
    justify-content: space-between;
    .button { color: var.$blue-light; }
  }
}
</style>
