<template>
  <Modal
    id="modal_devis_confirm_deletion"
    variant="warning"
    @change="!$event && hide()"
  >
    <template #title>
      {{ $t('commande.suppression-devis') }}
    </template>
    <template #content>
      <p class="text-regular">
        {{ $t("commande.vous-etes-sur-le-point-supprimer-devis") }}
      </p>
    </template>
    <template #footer>
      <button
        class="button text-medium underline"
        @click="hide()"
      >
        {{ $t('general.retour') }}
      </button>
      <ButtonClassic
        variant="solid"
        color="primary"
        :label="$t('commande.confirmer-suppression')"
        @click="confirm()"
      />
    </template>
  </Modal>
</template>

<script>
import { ButtonClassic, Modal } from "@lde/core_lde_vue";

/**
 * Modale permettant de confirmer ou non une suppression de devis
 */
export default {
  name: "ModalDevisConfirmDeletion",
  components: {
    Modal,
    ButtonClassic,
  },
  props: {
    toDelete: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton de confirmation.
     */
    "confirm",
  ],
  data() {
    return {
    };
  },
  methods: {
    /**
     * Ferme la modale.
     */
    hide() {
      this.$modal.hide("modal_devis_confirm_deletion");
    },
    /**
     * Confirme la suppression et ferme la modale.
     */
    confirm() {
      this.$emit("confirm", this.toDelete);
      this.hide();
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_devis_confirm_deletion {
  .modal-body p { padding: var.$space-x-tiny; }

  .modal-footer {
    display: flex;
    justify-content: space-between;
  }

  .button { color: var.$blue-light; }
}
</style>
